import sortBy from "lodash/sortBy";

import { parseISO } from "date-fns";

export function parseDailyBackstockReports(reports, today) {
  // Order by date
  const parsedReports = sortBy(reports, (r) => r.businessDate);
  const totals = { backstocksBinsAdded: 0, backstocksBinsPulled: 0 };

  parsedReports.forEach((report) => {
    //  TODO: check use?
    report.backstocksBinsPM =
      report.backstocksBinsAdded - report.backstocksBinsPulled;
    totals.backstocksBinsAdded += report.backstocksBinsAdded;
    totals.backstocksBinsPulled += report.backstocksBinsPulled;
  });

  return { reports: parsedReports, totals };
}

export function parseDailyReports(reports, today) {
  // Order by date
  const parsedReports = sortBy(reports, (r) => r.businessDate);

  const totals = {};
  const max = {};
  const min = {};

  parsedReports.forEach((report) => {
    // Calculate the p/m and totals
    report.salesGoalPM = report.sales - report.salesGoal;
    report.salesCompPM = report.sales - report.salesComp;

    report.buysCompPM = report.buys - report.buysComp;
    report.buysCountCompPM = report.buysCount - report.buysCountComp;

    report.customerCountCompPM =
      report.customerCount - report.customerCountComp;

    report.avgTransCompPM = report.avgTrans - report.avgTransComp;

    // task metrics not computed for this day
    if (report.activeTasks === 0) {
      report.taskCompletion = undefined;
    } else {
      report.taskCompletion = report.completedTasks / report.activeTasks;
    }

    // TOdo - check if we can get this from db also ? -> buysAvgDollars
    if (
      report.buys === null ||
      report.buysCount === null ||
      report.buys === undefined ||
      report.buysCount === undefined
    ) {
      report.buysAvgDollars = null;
    } else if (report.buys == 0 || report.buysCount == 0) {
      report.buysAvgDollars = 0;
    } else {
      report.buysAvgDollars = report.buys / report.buysCount;
    }

    // Transform into percentage
    report.tradePct = report.tradePct / 100;

    const isBeforeToday = today > parseISO(report.businessDate);

    // Calculate totals that are just sumation
    for (const key in report) {
      if (
        key != "businessDate" &&
        key != "storeId" &&
        key !== "compDate" &&
        key !== "__typename"
      ) {
        if (!totals[key]) {
          totals[key] = 0;
        }

        if (isBeforeToday) {
          const mtdField = `${key}_MTD`;
          if (!totals[mtdField]) {
            totals[mtdField] = 0;
          }
          totals[mtdField] += report[key];
        }

        totals[key] += report[key];

        if (!max[key]) {
          max[key] = report[key];
        }
        if (!min[key]) {
          min[key] = report[key];
        }
        if (report[key] > max[key]) {
          max[key] = report[key];
        }
        if (report[key] < min[key]) {
          min[key] = report[key];
        }
      }
    }
  });

  // Calculate totals that are not just sumation
  totals.buysAvgDollars = totals.buys / totals.buysCount;
  // For wait time we do not show total
  totals.buysAvgWaitTime = null;

  // Calculations below we do only for totals. For dailys data - we get data from database.
  totals.grossMargin = (totals.sales - totals.cogs) / totals.sales;
  totals.avgTrans = totals.sales / totals.customerCount;
  totals.avgTrans_MTD = totals.sales_MTD / totals.customerCount_MTD;
  totals.avgTransComp = totals.salesComp / totals.customerCountComp;
  totals.avgTransComp_MTD = totals.salesComp_MTD / totals.customerCountComp_MTD;
  totals.avgTransCompPM = totals.avgTrans - totals.avgTransComp;
  totals.avgTransCompPM_MTD = totals.avgTrans_MTD - totals.avgTransComp_MTD;
  totals.tradePct = totals.trades / totals.buys;
  totals.tradePctComp = totals.tradesComp / totals.buysComp;
  totals.laborPct = totals.payroll / totals.sales;
  totals.laborEfficiency = totals.sales / totals.laborHours;
  totals.taskCompletion =
    totals.activeTasks === 0
      ? undefined
      : totals.completedTasks / totals.activeTasks;

  return { reports: parsedReports, totals, max, min };
}
